import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/marketingFee'

export default {
  all (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/all${queryParams}`)
  },

  getReceiver (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/getReceiver${queryParams}`)
  },

  save (params) {
    return httpClient.post(`${endpoint}`, params)
  }
}
