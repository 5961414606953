<template>
  <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <!--sidebar-->
    <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="sidebar.clickNotClose" :hidden-background="sidebar.clickNotClose" v-model="sidebar.active">
      <component :is="scrollbarTag" class="todo-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.60 }">
        <div class="px-6">
          <div class="vx-card vx-col w-full mb-2 shadow-none">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-select autocomplete v-model="sidebar.proyek.selected" class="w-full">
              <vs-select-item v-for="(it, index) in sidebar.proyek.items" :key="index" :value="it" :text="it.nama"/>
            </vs-select>
          </div>
          <div class="vx-card vx-col w-full mb-2 shadow-none">
            <label class="ml-1 text-xs">Properti Tipe</label>
            <vs-select autocomplete v-model="sidebar.propertiTipe.selected" class="w-full">
              <vs-select-item v-for="(it, index) in sidebar.propertiTipe.items" :key="index" :value="it" :text="it.nama"/>
            </vs-select>
          </div>
          <div class="vx-card vx-col w-full mb-2 shadow-none">
            <label class="ml-1 text-xs">Rencana Bayar</label>
            <vs-select autocomplete v-model="sidebar.caraBayar.selected" class="w-full">
              <vs-select-item v-for="(itm, index) in sidebar.caraBayar.items" :key="index" :value="itm" :text="itm.nama"/>
            </vs-select>
          </div>
          <div v-if="sidebar.loading" class="animate-pulse">
            <h5>Loading...</h5>
            <vs-progress indeterminate color="primary" :height="1"/>
          </div>
        </div>
      </component>
    </vs-sidebar>

    <!--box-->
    <div :class="{'sidebar-spacer': sidebar.clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">
      <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
        <!--toggle sidebar-->
        <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleSidebar(true)"/>

        <!--search -->
        <div class="w-full flex">
          <vs-input icon-no-border size="large" icon-pack="feather" icon="icon-search" placeholder="Cari" v-model="search" class="vs-input-no-border vs-input-no-shdow-focus w-7/12 sm:w-10/12"/>
          <vs-button @click="save" color="primary" type="filled" class="w-5/12 sm:w-2/12 rounded-none" :disabled="!box.unsaved || box.saving">
            <span v-if="box.saving" class="animate-pulse">Menyimpan...</span>
            <span v-if="!box.saving">Simpan</span>
          </vs-button>
        </div>
      </div>

      <!--main content -->
      <component :is="scrollbarTag" class="todo-content-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.60 }">
        <div class="w-full" :class="{'px-2 pt-4': box.errors !== null}"><ValidationErrors :errors="box.errors"/></div>
        <h6 v-if="isBoxLoading"  class="p-10 text-center animate-pulse">Loading...</h6>
        <p v-if="!isBoxLoading && isBoxEmpty" class="p-10 text-center">Tidak ada data.</p>

        <div class="w-full p-2" v-if="!isBoxEmpty && !isBoxLoading">
          <!--------CHECKED-------->
          <div>
            <vx-card v-for="(item) in boxItemsChecked" :key="item.id" class="rounded-none mb-2 border border-primary border-solid">
              <div class="vx-row">
                <div class="vx-col w-4/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-start">
                    <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="true" @change="onUnchecked(item.id)"/>
                    <h5 class="todo-title">{{ item.name_roles }}</h5>
                  </div>
                </div>
                <div class="vx-col w-2/6 ml-auto flex sm:justify-end">
                  <feather-icon
                    :icon="item.showEdit ? 'CheckIcon' : 'EditIcon'"
                    class="cursor-pointer"
                    svgClasses="text-warning stroke-current w-5 h-5"
                    @click="item.showEdit = !item.showEdit"/>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full px-12">
                  <span @click.stop="showModalSetSyarat(item)" class="whitespace-no-wrap p-1 text-xs leading-none text-white bg-primary cursor-pointer">
                    {{ item.syarats.length }} Ketentuan
                  </span>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full px-12">
                  <p class="mt-1 truncate">Komisi: {{ item.komisi_new_persen || 0 }}%, Closing Fee: Rp{{ (item.closing_fee || 0) | idr }}</p>
                </div>
              </div>
              <div v-if="item.showEdit">
                <vs-divider color="warning">Edit Area</vs-divider>
                <div class="vx-row mb-3">
                  <div class="vx-col sm:w-6/12 w-full">
                    <label class="ml-1 text-xs">Komisi</label>
                    <vx-input-group>
                      <vs-input class="w-full" type="number" v-model="item.komisi_new_persen" @change="box.unsaved = true"/>
                      <template slot="append">
                        <div class="append-text bg-primary"><span>%</span></div>
                      </template>
                    </vx-input-group>
                  </div>
                  <div class="vx-col sm:w-6/12 w-full">
                    <label class="ml-1 text-xs">Closing Fee</label>
                    <vx-input-group>
                      <v-money class="w-full" type="number" v-model="item.closing_fee" @change="box.unsaved = true"/>
                      <template slot="append">
                        <div class="append-text bg-primary"><span>IDR</span></div>
                      </template>
                    </vx-input-group>
                  </div>
                </div>

                <div class="vx-row mb-3">
                  <div class="vx-col sm:w-12/12 w-full">
                    <vs-button @click="showModalSetSyarat(item)" class="w-full">{{ item.syarats.length }} Ketentuan</vs-button>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>

          <!--------UNCHECKED-------->
          <div>
            <div v-for="item in boxItemsUnchecked" :key="item.id" class="p-6 vx-card rounded-none mb-2 bg-opacity-25">
              <div class="vx-row">
                <div class="vx-col w-5/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-start">
                    <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="false" @change="onChecked(item.id)"/>
                    <h5 class="todo-title">{{ item.name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </component>

    </div>

    <!--modals-->
    <SetFeeSyarat :isActive.sync="modalSetSyarat.active"
                  :information="modalSetSyarat.information"
                  :syarats="modalSetSyarat.item.syarats"
                  @set="onSetSyarats($event)"/>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import vSelect from 'vue-select'
import { v4 as uuid } from 'uuid'
import MarketingFeeRepository from '@/repositories/marketing/marketing-fee-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import RolesRepository from '@/repositories/master/roles-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import SetFeeSyarat from '@/views/pages/marketing/marketing-fee/SetFeeSyarat'

export default {
  name: 'SetFee',
  components: {
    SetFeeSyarat,
    VMoney,
    ValidationErrors,
    VuePerfectScrollbar,
    'v-select': vSelect
  },
  mounted () {
    this.getAllCaraBayar()
    this.getAllProyek()
    this.getDataBox()
  },
  created () {
    this.setSidebarWidth()
  },
  data () {
    return {
      search: '',
      sidebar: {
        clickNotClose: true,
        active: true,
        loading: false,
        proyek: {
          items: [],
          selected: {}
        },
        propertiTipe: {
          items: [],
          selected: {}
        },
        caraBayar: {
          items: [],
          selected: {}
        }
      },
      box: {
        errors: null,
        loading: false,
        saving: false,
        unsaved: false,
        items: [],
        checked: []
      },
      modalSetSyarat: {
        active: false,
        information: {},
        item: {
          syarats: []
        }
      }
    }
  },
  watch: {
    'sidebar.proyek.selected' () {
      this.getAllPropertiTipe()
    },
    'sidebar.propertiTipe.selected' (newVal) {
      if (!_.isEmpty(newVal) && !_.isEmpty(this.sidebar.caraBayar.selected)) {
        this.refreshDataBox()
      }
    },
    'sidebar.caraBayar.selected' (newVal) {
      if (!_.isEmpty(newVal) && !_.isEmpty(this.sidebar.propertiTipe.selected)) {
        this.refreshDataBox()
      }
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    boxItemsChecked () {
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(this.box.checked, (item) => {
          return item.name_roles.toLowerCase().includes(search)
        })
      }
      return this.box.checked
    },
    boxItemsUnchecked () {
      const uncheckedItems = _.reject(this.box.items, (item) => _.find(this.box.checked, { id_roles: item.id }))
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(uncheckedItems, (item) => {
          return item.name.toLowerCase().includes(search)
        })
      }
      return uncheckedItems
    },
    isBoxLoading () {
      return this.box.loading
    },
    isBoxEmpty () {
      return (this.box.items.length < 1 && this.box.checked.length < 1) || this.sidebar.proyek.selected.id == null || this.sidebar.propertiTipe.selected.id == null
    },
    isSidebarEmpty () {
      return this.sidebar.proyek.items.length < 1 && !this.sidebar.loading
    },
    isUnsavedChanges () {
      return this.box.unsaved
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  },

  methods: {
    onChecked (id) {
      const boxItem = _.find(this.box.items, { id: id })
      const sidebar = this.sidebar
      const newItemChecked = {
        id: uuid(),
        id_proyek: sidebar.proyek.selected.id,
        id_properti_tipe: sidebar.propertiTipe.selected.id,
        id_cara_bayar: sidebar.caraBayar.selected.id,
        id_roles: boxItem.id,
        name_roles: boxItem.name,
        kode_roles: boxItem.kode,
        kode_proyek: sidebar.proyek.selected.kode,
        komisi_new_persen: 0,
        komisi_cancel_persen: 0,
        closing_fee: 0,
        syarats: [],
        showEdit: false
      }

      setTimeout(() => {
        this.box.checked.push(newItemChecked)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    onUnchecked (id) {
      setTimeout(() => {
        const index = _.findIndex(this.box.checked, item => item.id === id)
        this.box.checked.splice(index, 1)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    getDataBox () {
      RolesRepository.getAll()
        .then(response => {
          this.box.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getDataBoxChecked () {
      this.box.loading = true
      this.box.checked = []
      const params = {
        id_proyek: this.sidebar.proyek.selected.id,
        id_properti_tipe: this.sidebar.propertiTipe.selected.id,
        id_cara_bayar: this.sidebar.caraBayar.selected.id,
        withSyarats: true
      }

      MarketingFeeRepository.all(params)
        .then(response => {
          const additionalKeys = { showEdit: false }
          this.box.checked = _.map(response.data.data, function (item) {
            return _.extend({}, item, additionalKeys)
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.box.loading = false
        })
    },

    refreshDataBox () {
      this.clearBox()
      this.getDataBox()
      this.getDataBoxChecked()
      this.box.unsaved = false
    },

    clearBox () {
      this.box.items = []
      this.box.checked = []
    },

    getAllProyek () {
      this.sidebar.loading = true
      ProyekRepository.getAll()
        .then(response => {
          this.sidebar.proyek.items = response.data.data
          if (this.sidebar.proyek.items.length > 0) {
            this.sidebar.proyek.selected = this.sidebar.proyek.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.sidebar.loading = false
        })
    },

    getAllPropertiTipe () {
      this.sidebar.propertiTipe.selected = {}
      this.sidebar.propertiTipe.items = []
      this.sidebar.loading = true
      PropertiTipeRepository.getAll()
        .then(response => {
          this.sidebar.propertiTipe.items = response.data.data
          if (this.sidebar.propertiTipe.items.length > 0) {
            this.sidebar.propertiTipe.selected = this.sidebar.propertiTipe.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.sidebar.loading = false
        })
    },

    getAllCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'KONTRAK' })
      CaraBayarRepository.getAll(params)
        .then(response => {
          this.sidebar.caraBayar.items = response.data.data
          if (this.sidebar.caraBayar.items.length > 0) {
            this.sidebar.caraBayar.selected = this.sidebar.caraBayar.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    save () {
      this.box.errors = null
      this.box.saving = true

      const params = {
        id_proyek: this.sidebar.proyek.selected.id,
        id_properti_tipe: this.sidebar.propertiTipe.selected.id,
        id_cara_bayar: this.sidebar.caraBayar.selected.id,
        items: this.box.checked
      }
      MarketingFeeRepository.save(params)
        .then(response => {
          this.box.unsaved = false
          this.getDataBoxChecked()
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.box.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.box.saving = false
        })
    },

    onSidebarSelected (item) {
      // confirm unsaved
      if (this.isUnsavedChanges) {
        this.$vs.dialog({
          ...this.confirmLeaveOptions(),
          accept: () => {
            this.box.unsaved = false
            this.onSidebarSelected(item)
          }
        })
        return
      }

      this.sidebar.selected = item
    },

    showModalSetSyarat (item) {
      this.modalSetSyarat.information = {
        id_proyek: this.sidebar.proyek.selected.id,
        nama_proyek: this.sidebar.proyek.selected.nama,
        id_properti_tipe: this.sidebar.propertiTipe.selected.id,
        nama_properti_tipe: this.sidebar.propertiTipe.selected.nama,
        id_cara_bayar: this.sidebar.caraBayar.selected.id,
        nama_cara_bayar: this.sidebar.caraBayar.selected.nama,
        id_role: item.id_roles,
        nama_role: item.name_roles
      }
      this.modalSetSyarat.item = item
      this.modalSetSyarat.active = true
    },

    onSetSyarats (syarats) {
      const id = this.modalSetSyarat.item.id
      const index = _.findIndex(this.box.checked, item => item.id === id)
      this.box.checked[index].syarats = syarats
      this.box.unsaved = true
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.sidebar.active = this.sidebar.clickNotClose = false
      } else {
        this.sidebar.active = this.sidebar.clickNotClose = true
      }
    },

    toggleSidebar (value = false) {
      if (!value && this.sidebar.clickNotClose) return
      this.sidebar.active = value
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.box.unsaved) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/todo.scss";
</style>
