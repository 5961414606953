<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Ketentuan / Syarat" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <vs-alert v-if="isActive" class="mb-4" icon-pack="feather" icon="icon-alert-circle">
          <p>Silahkan tambahkan ketentuan komisi cair untuk jabatan <span class="font-bold uppercase">{{ information.nama_role }}</span> pada proyek <span class="font-bold uppercase">{{ information.nama_proyek }}</span> (tipe unit <span class="font-bold uppercase">{{ information.nama_properti_tipe }}</span>) saat rencana bayar kontrak adalah <span class="font-bold uppercase">{{ information.nama_cara_bayar }}</span>.</p>
        </vs-alert>

        <ValidationErrors :errors="errorMessages"/>

        <div class="vx-row mb-3" :class="{'mt-10': errorMessages.length < 1}">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vs-table :data="items" stripe class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Urut</vs-th>
            <vs-th class="whitespace-no-wrap">Ketentuan / Syarat Cair *</vs-th>
            <vs-th class="whitespace-no-wrap">Komisi Cair *</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm align-top">
              <vs-td class="w-4">
                <vs-button color="danger" class="py-2 px-2 mt-1" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.id)"/>
              </vs-td>
              <vs-td class="w-4 font-semibold"><p class="mt-2">{{ index + 1 }}</p></vs-td>
              <!--syarat cair-->
              <vs-td class="w-6/12">
                <div class="flex">
                  <vs-select class="w-full" v-model="item.syarat_cair" @change="onChangeSyaratCair(item)">
                    <vs-select-item v-for="(it, index) in item.optionsSyaratCair" :key="index" :value="it.code" :text="it.code"/>
                  </vs-select>
                  <vx-input-group class="ml-1 w-48" v-if="item.syarat_cair === 'PERSEN TERCAPAI'">
                    <vs-input class="w-full" type="number" v-model="item.syarat_cair_persen" @input="onChangeSyaratCairPersen(item)"/>
                    <template slot="append">
                      <div class="append-text bg-primary" :class="{'bg-danger': (item.syarat_cair_persen > 100 || !parseFloat(item.syarat_cair_persen))}"><span>%</span></div>
                    </template>
                  </vx-input-group>
                </div>
                <p class="text-xs p-1 opacity-75">{{ item.syarat_cair_description }}</p>
              </vs-td>

              <!--komisi cair-->
              <vs-td class="w-6/12">
                <div class="flex">
                  <vs-select class="w-full" v-model="item.komisi_cair" @change="onChangeKomisiCair(item)" :disabled="!item.syarat_cair">
                    <vs-select-item v-for="(it, index) in item.optionsKomisiCair" :key="index" :value="it.code" :text="it.code"/>
                  </vs-select>
                  <vx-input-group class="ml-1 w-48" v-if="item.komisi_cair === 'PERSEN TOTAL KOMISI'">
                    <vs-input class="w-full" type="number" v-model="item.komisi_cair_persen" @input="onChangeKomisiCairPersen(item)"/>
                    <template slot="append">
                      <div class="append-text bg-primary" :class="{'bg-danger': (item.komisi_cair_persen > 100 || !parseFloat(item.komisi_cair_persen))}"><span>%</span></div>
                    </template>
                  </vx-input-group>
                </div>
                <p class="text-xs p-1 opacity-75">{{ item.komisi_cair_description }}</p>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="onCancel">Batal</vs-button>
            <vs-button @click="onSet" :disabled="errorMessages.length > 0 || items.length < 1">Set</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'SetFeeSyarat',
  props: {
    isActive: { required: true, type: Boolean },
    information: { required: true, type: Object },
    syarats: { required: true, type: Array }
  },
  components: {
    ValidationErrors
  },
  watch: {
    isActive (active) {
      if (active) {
        this.items = _.map(this.syarats, item => {
          const syaratCairCode = item.syarat_cair
          item.optionsSyaratCair = this.optionsSyaratCair
          if (syaratCairCode === 'CASH IN') {
            item.optionsKomisiCair = this.filterOptionsKomisiCair(['SESUAI CASH IN'])
          } else if (syaratCairCode === 'PERSEN TERCAPAI') {
            const isSyaratCairCashInAlreadySelected = _.filter(this.syarats, item => item.syarat_cair === 'CASH IN').length > 0
            if (isSyaratCairCashInAlreadySelected) {
              item.optionsKomisiCair = this.filterOptionsKomisiCair(['SISA'])
            } else {
              item.optionsKomisiCair = this.filterOptionsKomisiCair(['PERSEN TOTAL KOMISI', 'SISA'])
            }
          } else if (syaratCairCode === 'AKAD KREDIT') {
            item.optionsKomisiCair = this.filterOptionsKomisiCair(['SISA'])
          }
          return item
        })
      }
    }
  },
  computed: {
    isAnyUnsavedChanges () {
      return !_.isEqual(_.cloneDeep(this.syarats), _.cloneDeep(this.items))
    },
    isKpr () {
      const idCaraBayarKpr = this.$systemParam('id_cara_bayar_kpr')
      return this.information.id_cara_bayar.toString() === idCaraBayarKpr.toString()
    },
    syaratCairCashInCount () {
      return _.filter(this.items, item => item.syarat_cair === 'CASH IN').length
    },
    syaratCairAkadKreditCount () {
      return _.filter(this.items, item => item.syarat_cair === 'AKAD KREDIT').length
    },
    errorMessages () {
      const errorMessages = []

      // syarat CASH IN hanya dipilih sekali
      const isSyaratCairCashInSelectedMoreThanOne = this.syaratCairCashInCount > 1
      if (isSyaratCairCashInSelectedMoreThanOne) {
        errorMessages.push('Syarat CASH IN hanya boleh dipilih sekali.')
      }

      // syarat AKAD KREDIT hanya dipilih sekali
      const isSyaratCairAkadKreditSelectedMoreThanOne = this.syaratCairAkadKreditCount > 1
      if (isSyaratCairAkadKreditSelectedMoreThanOne) {
        errorMessages.push('Syarat AKAD KREDIT hanya boleh dipilih sekali.')
      }

      // syarat cair persen tidak boleh sama
      const listOfPercents = _.map(_.filter(this.items, it => it.syarat_cair_persen), item => item.syarat_cair_persen)
      const grouped = _.groupBy(listOfPercents, item => item)
      const result = _.uniq(_.flatten(_.filter(grouped, item => item.length > 1)))
      _.each(result, percent => {
        errorMessages.push(`Syarat tercapai ${percent}% tidak boleh ada lebih dari satu.`)
      })

      // komisi cair SISA hanya dipilih sekali
      const isKomisiCairJenisSisaSelectedMoreThanOne = _.filter(this.items, item => item.komisi_cair === 'SISA').length > 1
      if (isKomisiCairJenisSisaSelectedMoreThanOne) {
        errorMessages.push('Komisi cair SISA hanya boleh dipilih sekali.')
      }

      // total komisi cair maksimal 100%
      const totalPersenKomisiCair = _.sumBy(_.filter(this.items, item => item.komisi_cair === 'PERSEN TOTAL KOMISI'), it => parseFloat(it.komisi_cair_persen))
      if (totalPersenKomisiCair > 100) {
        errorMessages.push('Total komisi cair tidak boleh melebihi 100%.')
      }

      // jenis komisi cair SISA hanya jika komisi cair belum 100%
      const isKomisiCairSisaAlreadySelected = _.findIndex(this.items, item => item.komisi_cair === 'SISA') !== -1
      if (totalPersenKomisiCair === 100 && isKomisiCairSisaAlreadySelected) {
        errorMessages.push('Jenis komisi cair SISA tidak dapat dipilih karena total komisi cair sudah 100%.')
      }

      // tidak dapat memilih cash in lagi saat sudah ada pilihan cair PERSEN TOTAL KOMISI
      const isExistSyaratCairCashIn = this.syaratCairCashInCount > 0
      const isExistKomisiCairPersenTotalKomisi = _.findIndex(this.items, item => item.komisi_cair === 'PERSEN TOTAL KOMISI') !== -1
      if (isExistSyaratCairCashIn && isExistKomisiCairPersenTotalKomisi) {
        errorMessages.push('Syarat CASH IN hanya dapat dipilih saat jenis komisi cair PERSEN TOTAL KOMISI tidak ada.')
      }

      // total komisi cair harus 100% atau harus ada jenis komisi cair SISA / SESUAI CASH IN
      const isKomisiCairJenisPersenTotalKomisiAlreadySelected = _.findIndex(this.items, item => item.komisi_cair === 'PERSEN TOTAL KOMISI') !== -1
      const totalKomisiCairPersen = _.sumBy(this.items, item => parseFloat(item.komisi_cair_persen || 0))
      if (isKomisiCairJenisPersenTotalKomisiAlreadySelected && totalKomisiCairPersen < 100 && !isKomisiCairSisaAlreadySelected) {
        errorMessages.push('Total persen komisi cair harus 100% atau harus ada komisi cair jenis SISA dipilih.')
      }

      // sort syarat cair persen harus ascending
      let isSyaratCairPersenAsc = true
      _.each(this.items, (item, index) => {
        if (index !== (this.items.length - 1)) {
          const currentRowPersen = parseFloat(item.syarat_cair_persen)
          const nextRowPersen = parseFloat(this.items[index + 1].syarat_cair_persen)
          if (currentRowPersen > nextRowPersen) {
            isSyaratCairPersenAsc = false
          }
        }
      })
      if (!isSyaratCairPersenAsc) {
        errorMessages.push('Urutan syarat cair persen harus berurutan dari kecil ke besar (ascending).')
      }

      _.each(this.items, (item, index) => {
        // syarat cair persen maksimal 100%
        if (item.syarat_cair_persen > 100) {
          errorMessages.push(`[Row ${index + 1}] Syarat persen tercapai maksimal 100%`)
        }

        // wajib memilih jenis syarat cair
        if (!item.syarat_cair) {
          errorMessages.push(`[Row ${index + 1}] Syarat cair wajib dipilih.`)
        }

        // wajib memilih jenis komisi cair
        if (!item.komisi_cair) {
          errorMessages.push(`[Row ${index + 1}] Komisi cair wajib dipilih.`)
        }

        // persen harus diisi saat syarat cair PERSEN TERCAPAI
        if (item.syarat_cair === 'PERSEN TERCAPAI' && !parseFloat(item.syarat_cair_persen)) {
          errorMessages.push(`[Row ${index + 1}] Persen syarat cair wajib diisi.`)
        }

        // persen harus diisi saat komisi cair PERSEN TOTAL KOMISI
        if (item.komisi_cair === 'PERSEN TOTAL KOMISI' && !parseFloat(item.komisi_cair_persen)) {
          errorMessages.push(`[Row ${index + 1}] Persen komisi cair wajib diisi.`)
        }
      })

      return errorMessages
    },
    optionsSyaratCair () {
      const options = [
        { code: 'CASH IN', description: 'Jika ada pembayaran termin masuk' },
        { code: 'PERSEN TERCAPAI', description: 'Jika total nilai kontrak sudah terbayar sebesar X%' },
        { code: 'AKAD KREDIT', description: 'Jika status KPR kontrak sudah menjadi Akad Kredit' }
      ]
      const optionsNonKpr = _.filter(options, option => _.includes(['CASH IN', 'PERSEN TERCAPAI'], option.code))
      return this.isKpr ? options : optionsNonKpr
    },
    optionsKomisiCair () {
      return [
        { code: 'SESUAI CASH IN', description: 'Maka komisi cair sebesar uang masuk dikali persen komisi.' },
        { code: 'PERSEN TOTAL KOMISI', description: 'Maka komisi cair sebesar X% dari total nilai komisi.' },
        { code: 'SISA', description: 'Maka komisi cair sebesar sisa komisi yang belum terbayarkan.' }
      ]
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    onChangeSyaratCair (item) {
      const syaratCairCode = item.syarat_cair
      item.syarat_cair_persen = syaratCairCode === 'PERSEN TERCAPAI' ? 0 : null
      item.syarat_cair_description = _.find(this.optionsSyaratCair, option => option.code === syaratCairCode).description
      item.komisi_cair = null

      if (syaratCairCode === 'CASH IN') {
        item.optionsKomisiCair = this.filterOptionsKomisiCair(['SESUAI CASH IN'])
        item.komisi_cair = 'SESUAI CASH IN'
      } else if (syaratCairCode === 'PERSEN TERCAPAI') {
        const isSyaratCairCashInAlreadySelected = this.syaratCairCashInCount > 0
        if (isSyaratCairCashInAlreadySelected) {
          item.optionsKomisiCair = this.filterOptionsKomisiCair(['SISA'])
          item.komisi_cair = 'SISA'
        } else {
          item.optionsKomisiCair = this.filterOptionsKomisiCair(['PERSEN TOTAL KOMISI', 'SISA'])
        }
      } else if (syaratCairCode === 'AKAD KREDIT') {
        item.optionsKomisiCair = this.filterOptionsKomisiCair(['SISA'])
        item.komisi_cair = 'SISA'
      }
    },

    onChangeKomisiCair (item) {
      const komisiCairCode = item.komisi_cair
      item.komisi_cair_persen = komisiCairCode === 'PERSEN TOTAL KOMISI' ? 0 : null
      item.komisi_cair_description = komisiCairCode ? _.find(this.optionsKomisiCair, option => option.code === komisiCairCode).description : null
    },

    onChangeSyaratCairPersen (item) {
      if (item.syarat_cair === 'PERSEN TERCAPAI') {
        const defaultDescription = _.find(this.optionsSyaratCair, option => option.code === item.syarat_cair).description
        const index = _.findIndex(this.items, it => it.id === item.id)
        this.items[index].syarat_cair_description = defaultDescription.replace('X%', `${item.syarat_cair_persen || 'X'}%`)
      }
    },

    onChangeKomisiCairPersen (item) {
      if (item.komisi_cair === 'PERSEN TOTAL KOMISI') {
        const defaultDescription = _.find(this.optionsKomisiCair, option => option.code === item.komisi_cair).description
        const index = _.findIndex(this.items, it => it.id === item.id)
        this.items[index].komisi_cair_description = defaultDescription.replace('X%', `${item.komisi_cair_persen || 'X'}%`)
      }
    },

    filterOptionsKomisiCair (komisiCairCodes) {
      return _.filter(this.optionsKomisiCair, option => _.includes(komisiCairCodes, option.code))
    },

    addRow () {
      const item = {
        id: uuid(),
        optionsSyaratCair: this.optionsSyaratCair,
        syarat_cair: null,
        syarat_cair_persen: null,
        syarat_cair_description: null,
        optionsKomisiCair: [],
        komisi_cair: null,
        komisi_cair_persen: null,
        komisi_cair_description: null
      }
      this.items.push(item)
    },

    removeRow (id) {
      const index = _.findIndex(this.items, item => item.id === id)
      this.items.splice(index, 1)
    },

    confirmLeave () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Terdapat perubahan yang belum disimpan, yakin ingin close popup?',
        acceptText: 'Ya',
        cancelText: 'Batal',
        accept: () => { this.$emit('update:isActive', false) }
      })
    },

    onSet () {
      this.emitOnSet()
      this.$emit('update:isActive', false)
    },

    onCancel () {
      this.emitModalIsActive(false)
    },

    emitOnSet () {
      const itemsFiltered = _.filter(this.items, item => item.syarat_cair)
      this.$emit('set', itemsFiltered)
    },

    emitModalIsActive (isActive) {
      if (!isActive && this.isAnyUnsavedChanges) return this.confirmLeave()
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
